'use strict'

# スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,{
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
  }
)

smoothScroll = (target, duration)->
  if duration is null
    duration = 1000

  # if window.matchMedia('(max-width:750px)').matches
  scrollTo = $(target).offset().top - $('.l-header').innerHeight() - 50
  # else
  #   if $('#p-navi').length > 0
  #     scrollTo = $(target).offset().top - $('#p-navi').height() - 15
  #   else
  #     scrollTo = $(target).offset().top - 15

  $(
    if (navigator.userAgent.indexOf('Opera') isnt -1)
    then (
      if document.compatMode is 'BackCompat'
      then 'body'
      else 'html'
    )
    else 'html,body'
  ).animate({
    scrollTop: scrollTo
  },{
    easing:'easeInOutCirc',
    duration: duration
  })
